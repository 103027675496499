import { register } from 'swiper/element/bundle';
import images from '../imageData';

register();

export default function Gallery() {

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
        <swiper-container navigation="true" pagination="true" scrollbar="true" lazy="true"
        style={{
          '--swiper-navigation-color': '#ffffff',
          '--swiper-pagination-color': '#ffffff',
          'height': '500px' // Add this line
        }}
        >
        {images.map(({ path, alt }, index) => (
          <swiper-slide key={index}>
              <img
                src={path}
                className="h-full w-full object-cover object-center"
                alt={alt}
                loading="lazy"
              />
          </swiper-slide>
          ))}
        </swiper-container>
        </div>
      </div>
    </div>
  );
  }
  