import { useState, useContext, useEffect } from 'react';
import { LanguageContext } from '../LanguageContext';
import { Field, Label, Switch } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

const LanguageSelection = () => {
    const { setLanguage } = useContext(LanguageContext);
    const savedLanguage = localStorage.getItem('language');
    const preferredLanguage = navigator.language.startsWith('vi') ? 'vi' : 'en';
    const initialLanguage = savedLanguage || preferredLanguage;
    const [language, setLanguageState] = useState(initialLanguage);
    const [enabled, setEnabled] = useState(initialLanguage === 'vi');
    
    useEffect(() => {
        setLanguage(language);
    }, [language, setLanguage]);

    const toggleLanguage = () => {
        const newLanguage = !enabled ? 'vi' : 'en';
        setEnabled(!enabled);
        setLanguageState(newLanguage);
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);
    }
    
    return (
        <Field as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={toggleLanguage}
        className={classNames(
          enabled ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      <Label as="span" className="ml-3 text-sm">
        <span className="font-medium text-gray-900">{language === 'vi' ? 'Vietnamese' : 'English'}</span>
      </Label>
    </Field>
    );
};

export default LanguageSelection;