import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Gallery from './pages/Gallary';
import FAQ from './pages/Faq';
import Hero from './pages/Hero';
import MainNavigation from './components/MainNavigation';

const navigation = [
  { name: 'navGallery', href: '/gallery' },
  { name: 'navFaqs', href: '/faq' },
  { name: 'navBookNow', href: 'https://www.airbnb.com/rooms/1163267261377809211' },
]

function App() {

  return (
    <Router>
    <div className="bg-white">
      <MainNavigation navigation={navigation} />
      <main>
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/faq" element={<FAQ />} />
        </Routes>
      </main>
    </div>
    </Router>
  )
  
}

export default App;
