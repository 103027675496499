import React, { createContext, useState } from 'react';
import en from './locales/en.json';
import vi from './locales/vi.json';

export const LanguageContext = createContext();

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState('en');
  const locales = language === 'en' ? en : vi;

  return (
    <LanguageContext.Provider value={{ locales, language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}